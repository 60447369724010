@import './variables.scss';
.order-index {
	height: 83vh;
	padding-bottom: 1.5vh;
	.col-container {
		display: flex;
		flex-direction: column;
		flex: 1;
		max-height: 100%;
		min-height: 97%;
		max-width: 92%;
	}
	.page-header-text {
		margin-top: 10px;
		margin-bottom: 0;
	}
	.active-filter {
		background-color: #212121;
		color: #fff;
	}
	.inactive-filter {
		background-color: #f4f4f4;
		color: #63666a;
	}
	.search-height {
		min-height: 60px;
	}
	.header-title {
		min-height: 30px;
	}
	.filter-buttons-height {
		min-height: 50px;
	}
	.sub-topic-height {
		min-height: 60px;
	}
	.main-topic-height {
		min-height: 43px;
	}
	.filter {
		width: 80px;
		height: 40px;
		border-radius: 8px;
		font-family: $CircularStdBlack;
		font-style: normal;
		font-weight: 450;
		font-size: 0.875rem;
		line-height: 1rem;
		border: 0px solid transparent;
		margin-top: 8px;
		.filter-button-icon {
			width: 20px;
			position: relative;
			left: -7px;
			z-index: 0;
		}
		span {
			position: relative;
			left: -5px;
		}
	}
	.filter:focus {
		border: 1px solid #d0d0d0;
	}
	.search {
		margin-top: 8px;
		background-color: #f4f4f4;
		border-radius: 8px;
		border: 0px;
		height: 40px;
		input {
			background-color: #f4f4f4;
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 450;
			font-size: 0.875rem;
			line-height: 1rem;
			color: #5f5f5f;
		}

		.ant-image-img {
			width: 8vw;
		}
	}
	.filter-name-text {
		margin-top: 16px;
		margin-bottom: 16px;
		font-family: $CircularStdBlack;
		font-style: normal;
		font-size: 1rem;
		line-height: 149.5%;
		color: #000000;
	}
	.order-container {
		flex: 1;
		overflow-y: auto;
		align-items: flex-start;
	}
}
